import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

import { ServicesContent } from './ServicesContent'

export interface Props {
  description?: string
  image?: ImageProps
  languageCode: string
  services?: string[]
  servicesTitle?: string
  subtitle?: string
  title?: string
}

export const MeetingIntro = memo(function MeetingIntro({
  description,
  image,
  languageCode,
  services,
  servicesTitle,
  subtitle,
  title,
}: Props) {
  return (
    <Container row tag="section">
      <LeftSide>
        <Fade left distance="7.5rem">
          <Text>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Text>
        </Fade>
        <Fade>
          <ServicesContent
            languageCode={languageCode}
            services={services}
            title={servicesTitle}
          />
        </Fade>
      </LeftSide>
      <RightSide>
        {image ? (
          <ParallaxProvider>
            <Parallax y={[0, 40]}>
              <ImageWrap>
                <Image {...image} />
              </ImageWrap>
            </Parallax>
          </ParallaxProvider>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding-top: 7.5rem;

  @media (max-width: 1199px) {
    padding-top: 0;
  }
`

const LeftSide = styled.div`
  width: 56%;
  .react-reveal {
    &:first-of-type {
      position: relative;
      z-index: 2;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    .react-reveal {
      &:first-of-type {
        animation: none !important;
      }
    }
  }
`

const Text = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.5rem 11.25vw 6rem 10vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 17.633vw 3.75rem 8.454vw;
  }

  @media (max-width: 767px) {
    padding-right: 8.454vw;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 5.25rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-top: 0.625rem;
  margin-bottom: 2.25rem;

  @media (max-width: 1199px) {
    margin-top: 0.375rem;
    margin-bottom: 1.875rem;
  }
`

const Description = styled.div`
  max-width: 477px;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const RightSide = styled.div`
  width: 44%;
  padding: 0 10vw;

  @media (max-width: 1199px) {
    display: none;
  }
`

const ImageWrap = styled.div`
  width: 100%;
  padding-bottom: 134%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`
